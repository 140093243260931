import React, { ReactNode } from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import DashboardSave from "../../../static/icons/pages/dashboard/download.svg";
import DashboardLines from "../../../static/icons/pages/dashboard/lines.svg";
import DashboardStars from "../../../static/icons/pages/dashboard/stars.svg";
import { DashboardCards } from "../../components/Dashboard/DashboardCards";
import { Colors, Hero } from "../../components/shared/Hero";
import { Button } from "../../components/shared/Button";
import MainImage from "../../images/dashboards/dashboard-image.svg";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { routes } from "../../routes/routes";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Seo } from "../../components/shared/Seo";

export type DashboardCard = {
  desc: string;
  icon: ReactNode;
};

const dashboardCards: DashboardCard[] = [
  {
    desc: "dashboard.card.save",
    icon: <DashboardSave />,
  },
  {
    desc: "dashboard.card.lines",
    icon: <DashboardLines />,
  },
  {
    desc: "dashboard.card.stars",
    icon: <DashboardStars />,
  },
];

function BIDashboard() {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const bgColor = Colors.transparent;
  return (
    <Layout>
      <Seo
        title={t("dashboard.title")}
        description={t("dashboard.text")}
        image={MainImage}
      />
      <Hero pic={{ wrap: Image, src: MainImage }} backgroundColor={bgColor}>
        <BreadCrumbsWrap>
          <BreadCrumbs
            root={{ title: "tbizedi", link: routes.tbizedi.edi }}
            current={"tbizediSubs.dashboards"}
          />
        </BreadCrumbsWrap>
        <Heading color={bgColor}>{t("dashboard.title")}</Heading>
        <HeroDesc color={bgColor}>{t("dashboard.text")}</HeroDesc>
        <ButtonsWrap>
          <Button onClick={() => navigate(routes.contact.contactForm)}>
            {t("eDataGatewayContactUs")}
          </Button>
        </ButtonsWrap>
      </Hero>
      <FunctionsSection>
        <CardsList>
          {dashboardCards.map((el, i) => (
            <DashboardCards key={i} icon={el.icon} desc={el.desc} />
          ))}
        </CardsList>
      </FunctionsSection>
    </Layout>
  );
}

export default BIDashboard;

const FunctionsSection = styled("section", {
  paddingBottom: "60px",
  backgroundColor: "$white",
  textAlign: "center",
  marginTop: "80px",
});

const CardsList = styled("ul", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 40,
  padding: "0 16px",
  maxWidth: "1280px",
  margin: "0 auto",
  "@md": {
    padding: 0,
    flexDirection: "row",
    gap: 30,
  },
});

const Image = styled("img", {
  objectFit: "contain",
  maxWidth: "484px",
  width: "90%",
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "30px 0 0",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    margin: "0",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
    maxWidth: "520px",
  },
});

const HeroDesc = styled("p", {
  color: "$white",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "12px 0px 32px 0px",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    color: "$text",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  "@md": {
    flexDirection: "row",
  },
});

const BreadCrumbsWrap = styled("div", {});
