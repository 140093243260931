import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactNode } from "react";
import { styled } from "../../../stitches.config";

type Props = {
  icon: ReactNode;
  desc: string;
};

export const DashboardCards: React.FC<Props> = ({ icon, desc }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <ImgWrap>{icon}</ImgWrap>
      <CardDesc>{t(desc)}</CardDesc>
    </Card>
  );
};

const Card = styled("div", {
  padding: "24px 16px",
  backgroundColor: "$secondaryBackground",
  borderRadius: "8px",
  textAlign: "center",
  height: "fix-content",
  minHeight: "222px",
  "@xs": {
    width: "700px",
    height: "240px",
  },
  "@md": {
    width: "406px",
    height: "240px",
  },
});

const ImgWrap = styled("span", {
  display: "block",
  width: "52px",
  height: "52px",
  backgroundColor: "$accent",
  margin: "0 auto",
  borderRadius: "50%",
  padding: "16px 12px",
});

const CardDesc = styled("p", {
  marginTop: "16px",
  marginBottom: "0px",
  fontSize: "14px",
  lineHeight: "21px",
  letterSpacing: "-0.022em",
  fontWeight: 400,
  color: "$cardText",
  "@md": {
    fontSize: "16px",
    marginTop: "16px",
  },
});
